<template>
  <div class="main">
    <div class="header-container">
      <list-header
        v-for="(list, index) in lists"
        :data="list"
        :template="resultListHeaderTemplate"
        :style="resultListWidth"
        :component-style="resultListHeaderStyle"
        :key="index"
      ></list-header>
    </div>
    <el-scrollbar :style="{height:'100%'}" wrap-style="overflow-x:hidden;">
      <div class="tasks-container">
        <el-collapse class="group-list" accordion v-if="groups.length > 0">
          <el-collapse-item v-for="(group, index) in groups" :key="index">
            <template slot="title">
              <group-title :data="group"></group-title>
            </template>
            <el-collapse class="group-list" accordion v-if="(group.children || []).length > 0">
              <el-collapse-item v-for="(child, childIndex) in group.children" :key="childIndex">
                <template slot="title">
                  <group-title :data="child"></group-title>
                </template>
                <tasks-container
                  :interactive="interactive"
                  :lists="lists"
                  :template="resultTaskTemplate"
                  :task-style="resultTaskStyle"
                  :result-list-width="resultListWidth"
                  :tasks="child.tasks"
                  :default-order="defaultOrder"
                  :condition-moving="conditionMoving"
                  :additional-fields="additionalFields"
                ></tasks-container>
              </el-collapse-item>
            </el-collapse>
            <tasks-container
              v-else
              :lists="lists"
              :interactive="interactive"
              :template="resultTaskTemplate"
              :task-style="resultTaskStyle"
              :result-list-width="resultListWidth"
              :tasks="group.tasks"
              :default-order="defaultOrder"
              :condition-moving="conditionMoving"
              :additional-fields="additionalFields"
            ></tasks-container>
          </el-collapse-item>
        </el-collapse>
        <tasks-container
          v-else
          :lists="lists"
          :interactive="interactive"
          :template="resultTaskTemplate"
          :task-style="resultTaskStyle"
          :result-list-width="resultListWidth"
          :tasks="tasks"
          :default-order="defaultOrder"
          :condition-moving="conditionMoving"
          :additional-fields="additionalFields"
        ></tasks-container>
      </div>
    </el-scrollbar>
    <div class="footer-container" v-if="footerIsVisible">
      <list-footer
        v-for="(list, index) in lists"
        :data="list"
        :template="resultListFooterTemplate"
        :style="resultListWidth"
        :component-style="resultListFooterStyle"
        :key="index"></list-footer>
    </div>
  </div>
</template>

<script>
  import ListHeader from './components/ListHeader'
  import ListFooter from './components/ListFooter'
  import GroupTitle from './components/GroupTitle'
  import TasksContainer from './components/TasksContainer'

  import { statuses, groups, tasks } from './data/data'
  import Vue from 'vue'

  export default {
    name: 'draggable-list',
    props: {
      interactive: {
        type: String,
        default: null
      },
      defaultOrder: {
        type: Object,
        default: {},
        description: 'Сортировка'
      },
      listHeaderTemplate: {
        type: Array,
        default: () => {
          return []
        }
      },
      listHeaderStyle: {
        type: String,
        default: ''
      },
      footerIsVisible: {
        type: Boolean,
        default: false
      },
      listFooterTemplate: {
        type: Array,
        default: () => {
          return []
        }
      },
      listFooterStyle: {
        type: String,
        default: ''
      },
      taskTemplate: {
        type: Array,
        default: () => {
          return []
        }
      },
      taskStyle: {
        type: String,
        default: ''
      },
      listWidth: {
        type: Number,
        default: 300
      },
      tasks: {
        type: Array,
        default: () => []
      },
      lists: {
        type: Array,
        default: () => []
      },
      groups: {
        type: Array,
        default: () => []
      },
      conditionMoving: {
        type: Object,
        default: () => {}
      },
      additionalFields: {
        type: Array,
        default: () => []
      },
    },
    components: {
      ListHeader,
      ListFooter,
      GroupTitle,
      TasksContainer
    },
    provide () {
      return {
        getEventBus: this.getEventBus
      }
    },
    data () {
      return {
        eventBus: new Vue()
      }
    },
    methods: {
      setStyleTemplate (template) {
        return template.map((block)=>{
          block.style = `overflow:hidden;white-space:nowrap;position: absolute;transform: translate(${block.x}px, ${block.y}px); z-index: auto; width: ${block.width}px; height: ${block.height}px;`
          return block
        })
      },
      getEventBus () {
        return this.eventBus
      },
      onChangeCustomSorting (list) {
        this.$emit('custom-change', list)
      },
      onChange (element) {
        this.$emit('change', element)
      }
    },
    mounted () {
      this.eventBus.$on('change', this.onChange)
      this.eventBus.$on('custom-change', this.onChangeCustomSorting)
    },
    beforeDestroy () {
      this.eventBus.$off('change', this.onChange)
      this.eventBus.$off('custom-change', this.onChangeCustomSorting)
    },
    computed: {
      resultTaskTemplate () {
        return this.setStyleTemplate(this.taskTemplate)
      },
      resultTaskStyle () {
        return `position: relative;${this.taskStyle}`
      },
      resultListFooterTemplate () {
        return this.setStyleTemplate(this.listFooterTemplate)
      },
      resultListFooterStyle () {
        return `z-index: 1;min-height: 48px;position: relative;${this.listFooterStyle}`
      },
      resultListHeaderTemplate () {
        return this.setStyleTemplate(this.listHeaderTemplate)
      },
      resultListHeaderStyle () {
        return `z-index: 1;min-height: 48px;position: relative;${this.listHeaderStyle}`
      },
      resultListWidth () {
        return `min-width: ${this.listWidth}px`
      }
    }
  }
</script>

<style scoped>
  .main {
    height: 100%;
    float: left;
    display: flex;
    flex-direction: column;
  }

  .main .footer-container, .main .header-container {
    padding: 10px;
    display: flex;
    flex-direction: row;
  }

  .main .tasks-container .group-list /deep/ .el-collapse-item.is-active {
    position: -webkit-sticky;
    position: sticky;
  }

  .main .header-container /deep/ div {
    margin-right: 10px;
  }

  .main .footer-container /deep/ div {
    margin-right: 10px;
  }

  .main .tasks-container {
    flex: 1 1 auto;
    margin-left: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
  }

  .main .tasks-container .group-list /deep/ .el-collapse-item /deep/ .el-collapse-item .el-collapse-item__header {
    margin-left: 20px;
  }

  .main .tasks-container .group-list {
    width: 100%;
    border: none;
  }

  .main .tasks-container .group-list /deep/ .el-collapse-item__header {
    direction: rtl;
    justify-content: flex-end;
  }

  .main .tasks-container .group-list /deep/ .el-collapse-item__arrow {
    margin-left: 5px;
    color: #89959F;
    font-weight: bold;
    font-size: 15px;
  }

  .main .tasks-container .group-list /deep/ .el-collapse-item__header, .main .tasks-container .group-list /deep/ .el-collapse-item__wrap {
    border: none;
  }

  .main .tasks-container .group-list /deep/ .el-collapse-item__content {
    padding-bottom: 0px;
  }
</style>
